<template>
  <div class="relative">
    <div
      ref="select"
      class="custom-select selector relative w-full flex h-36 sm:h-40 bg-white rounded-md rounded-b-0"
      :class="[textSize]"
      @blur="open = false"
    >
      <div
        v-if="selected"
        class="selected cursor-pointer flex justify-between pr-28 bg-white rounded-md w-full text-black items-center pl-12 border-grey400 border"
        :class="{
          'open rounded-b-none': open
        }"
        @click="simpleProductListing ? toggleDropdown() : open = !open"
      >
        <div v-if="selected.packageSize === 1 && items.length > 1" class="mt-3">
          {{ productResources.can }}
        </div>
        <div v-else-if="type === 'Bundle'" class="lowercase">
          1 {{ productResources.mixPackage }}
        </div>
        <div v-else-if="type !== 'Bundle' && items.length < 2">
          1 st
        </div>
        <div v-else class="mt-3">{{ selected.packageSize }}-pack</div>
        <div class="mt-3">{{ formatPrice(selected.price.value) }}</div>
      </div>
      <div
        class="items rounded-b-md top-[35px] sm:top-[39px] z-30 left-0 right-0 bg-white absolute border border-grey400 text-black"
        :class="{ hidden: !open }"
        :style="simpleProductListing ? dropdownStyle : ''"
      >
        <div
          v-for="(option, i) of items"
          :key="i"
          class="flex justify-between pr-28 pl-12 bg-white w-full items-center h-36 sm:h-40 border-b border-grey400 last:border-none last:rounded-b-md animate-all"
          :class="{
            'pointer-events-none cursor-auto !bg-grey200 text-grey800': !option.inStock && !useRunnerStockStatus,
            'text-black cursor-pointer hover:bg-grey200': option.inStock,
          }"
          tabindex="0"
          @click="
            selected = option;
            open = false;
            $emit('change', option);
          "
        >
          <div v-if="option.packageSize">
            <span v-if="option.packageSize === 1 && items.length > 1">
              {{ productResources.can }}
            </span>
            <span v-else-if="type === 'Bundle'" class="lowercase">
              1 {{ productResources.mixPackage }}
            </span>
            <span v-else-if="type !== 'Bundle' && items.length < 2">
              1 st
            </span>
            <span
              v-else
              :class="{
                'line-through': !option.inStock && !useRunnerStockStatus,
              }"
            >
              {{ option.packageSize }}-pack
            </span>
          </div>
          <div v-if="option.price">
            <span v-if="option.price.value">
              {{ formatPrice(option.price.value) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import useDetectOutsideClick from '~/composables/useDetectOutsideClick';
import { type IVariant, ProductType } from '~/api-types';
import { useGlobalContentStore } from '~/store/globalContent';
const productResources = useGlobalContentStore().productResources;
const productSettings = useGlobalContentStore().productSettings;
const globalContentStore = useGlobalContentStore();
const marketSettings = globalContentStore.marketSettings;

const selected = ref();
const open = ref();

interface Props {
  items: any,
  textSize?: string,
  type?: ProductType | string,
  isRunner?: boolean,
  simpleProductListing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  textSize: 'text-small-medium',
  type: ProductType.Standard,
  isRunner: false,
});

const useRunnerStockStatus = computed(() => {
  return props.isRunner && !productSettings.disablePurchaseIfOutOfStock;
});

onMounted(()=> {
  // Choose preselected variant if in stock, else choose first varient in stock
  if (props.items[0]) {
    let preferredVariant = props.items.find(variant => variant.isPreferredVariant);
    if (preferredVariant && preferredVariant.inStock) {
      selected.value = preferredVariant;
    } else if (
      productSettings?.preSelectedProductVariantIndex &&
      props.items[productSettings?.preSelectedProductVariantIndex] &&
      props.items[productSettings?.preSelectedProductVariantIndex].inStock || props.isRunner
    ) {
      selected.value = props.items[productSettings?.preSelectedProductVariantIndex];
    } else if (props.items.find(variant => variant.inStock)) {
      selected.value = props.items.find(variant => variant.inStock);
    } else {
      selected.value = props.items[0];
    }
  } else {
    selected.value = '';
  }

  emit('change', selected.value);

  window.addEventListener('resize', setDropDownStyle);
  window.addEventListener('scroll', setDropDownStyle);
});

const select = ref();

const dropdownStyle = ref({
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '0px',
});

useDetectOutsideClick(select, () => { 
  open.value = false;
});

const setDropDownStyle = () => {
  if (open.value) {
    const rect = select.value.getBoundingClientRect();
    dropdownStyle.value.top  = `${rect.bottom - 1}px`;
    dropdownStyle.value.left = `${rect.left}px`;
    dropdownStyle.value.width = `${rect.width}px`;
  }
};

const toggleDropdown = () => {
  open.value = !open.value;
  setDropDownStyle();
};

const emit = defineEmits<{
  (e: 'change', option: IVariant): void,
}>();

const formatPrice = (number: number) => {
  if (marketSettings.countryCode === 'NO') {
    const locale = marketSettings.locale as string;
    const formatter = new Intl.NumberFormat(locale);

    return formatter.format(number).replace(/\D00(?=\D*$)/, '') + ' NOK';
  } else {
    const currency = marketSettings.currency.currencyCode as string;
    const locale = marketSettings.locale as string;

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    });

    return formatter.format(number).replace(/\D00(?=\D*$)/, '');
  }
};
</script>

<style scoped lang="postcss">
.selector::after {
  content: '';
  background-image: url('/icons/chevron-down.svg');
  height: 18px;
  width: 20px;
  @apply top-1/2 transform -translate-y-1/2 right-4 pointer-events-none absolute bg-contain;
}
</style>
